import JotFormReact from "jotform-react";
import React from "react";
import V from '../asserts/recuriter.mp4';
import { images } from "../config/ConstImg";
import "../styles/Hire.css";

const HireGuard = () => {
  return (
    <div className="hire-box p-3">
      <span
        className="home-service-content-text-box-txt text-center"
        style={{ marginTop: "2%" }}
      >
        Recruitment <span className="app-color"></span>
      </span>
      <div className="hire-right">
        <img
          src={images.seclist01}
          alt="security images"
          className="hire-right-img"
        />
      </div>
      <div className="hire-box-content">
        <span className="hire-box-content-hd app-color">
          Insta-guard is actively seeking individuals with specific skill sets to join its team of security professionals. Here's how Insta-guard positions its recruitment campaign:
        </span>

        <div className="content-box-hire">
          <ul className='list-style-none ul-lister'>
            <li>
              <strong>Targeted Recruitment:</strong> <br /> Insta-guard is looking for security professionals who are CP qualified as-well as ex-police officers with a Close Protection License, as well as mature, experienced military professionals who are eager to contribute to their community. These individuals possess valuable skills and experience that can provide reassurance and support to those in need of urgent assistance.
            </li>
            <li>
              <strong>Flexibility and Autonomy:</strong> <br />Insta-guard offers the opportunity for individuals to plan and work according to their own schedule, providing flexibility and autonomy in managing their work diary.
            </li>
            <li>
              <strong>Diverse Opportunities:</strong> <br /> Insta-guard offers the chance to be part of a rural task force, catering to various security needs across different environments and settings.
            </li>
            <li>
              <strong>Community Impact:</strong><br /> By joining Insta-guard, individuals will contribute to their community and be a part of a pioneering service that benefits the city they live in.
            </li>
            <li>
              <strong>Competitive Compensation:</strong><br /> Insta-guard offers fantastic rates of pay to its security professionals, recognising the value of their skills and contributions.
            </li>
            <li>
              <strong>Security Automation and Innovation:</strong><br /> Insta-guard recognizes the importance of security automation and positions itself as a forward-thinking company that embraces technology to enhance security services.
            </li>
            <li>
              <strong>Join the Campaign:</strong><br /> Individuals who wish to be part of Insta-guard's mission to support the UK community are encouraged to join the campaign and subscribe to become part of the team.
            </li>
            <li>Overall, Insta-guard offers a compelling opportunity for skilled professionals to make a positive impact, contribute to community safety, and be part of an innovative and supportive company.
            </li>
            <div className="row">
              <div className="col-md-9">
                <li>
                  <strong>Public Liability Insurance:</strong><br /> Simply Business offers tailored public liability insurance for security guards from £3.49 per month. Coverage includes protection against third-party injuries and property damage, with options for additional cover such as employer’s liability, business equipment, and legal expenses insurance. Policies are customizable based on business needs, and quotes can be obtained online in about 7 minutes. Their services also include a 24/7 claims helpline and UK-based support.
                </li>
                <li>
                    For more detailed information, you can visit their <a href="https://www.simplybusiness.co.uk/insurance/public-liability/public-liability-insurance-for-security-guards/" target="blank" className="app-color">website</a>.
                </li>
              </div>
              <div className="col-md-3 mt-4">
                <img 
                  src={images.aboutImg07}
                  alt="insurence images"
                  style={{maxWidth:'100%'}}
                />
              </div>
            </div>
          </ul>

        </div>

      </div>
      <div className="container d-flex justify-content-center align-items-center my-5">
        <video className="container" style={{ maxWidth: "62em" }} src={V} controls controlsList="nodownload nofullscreen noremoteplayback"></video>
      </div>

      {/* <div className='row'>
        <div className='col-12 col-lg-6 col-md-6'>
          <JotFormReact formURL="https://form.jotform.com/233552743471053" />
        </div>
        <div className='col-12 col-lg-6 col-md-6'>

          <JotFormReact formURL="https://form.jotform.com/240893896737376" className='' />
        </div>
      </div> */}

    </div>
  );
};

export default HireGuard;
