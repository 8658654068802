import { IoIosArrowRoundForward } from "@react-icons/all-files/io/IoIosArrowRoundForward";
import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../Redux/Store";
import { images } from "../images";
import playstore from "../asserts/google.png";
import appstore from "../asserts/apple.png";

function Footer() {
  const navigate = useNavigate();

  const handleNavigate = (value) => {
    navigate(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer-cls">
      <div className="container footer-top">
        <div className="footer-top-con">
          <div className="footer-img">
            <img src={images.sendIcon} alt="send"></img>
            <div>
              <p className="footer-head">Post a Request</p>
              <p className="footer-desc">
                use this link to contact us 24 hours a day
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center ">
            <div>
              <button
                className="book-guard-btn"
                onClick={() => {
                  navigate("/services");
                  store.dispatch({ type: "setType", payload: 2 });
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Book a Guard &nbsp;
                <IoIosArrowRoundForward size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-12 col-9 mt-md-4">
            <img
              role={"button"}
              className=""
              src={images.logo}
              onClick={() => handleNavigate("/")}
              alt="footer-logo"
            />
            <br />
            <img
              role={"button"}
              className="mt-1"
              src={require("../asserts/demoe.gif")}
              alt="footer-logo"
              height={130}
              onClick={() => handleNavigate("/")}
              style={{ objectFit: "contain" }}
            />
            {/* <p className="footer-con">Keeping you and your property safe</p> */}
            {/* <p className="footer-con">
              We have <span className="red-txt">NOT</span> come to{" "}
              <span className="red-txt">BE</span> serve
              <span className="red-txt">D</span>
            </p> */}
          </div>
          <div className="col-xl-8 mt-4">
            <div className="row justify-content-center">
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Company</a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button" onClick={() => handleNavigate("/")}>
                      home page
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/services")}
                    >
                      Services
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button" onClick={() => handleNavigate("/aboutUs")}>
                      About us
                    </a>
                  </li>
                  {/* <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/howitworks")}
                    >
                      How it works
                    </a>
                  </li> */}
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/contactUs")}
                    >
                      Contact us
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => window.location.href = '/recruitment'}
                    >
                      Recruitment
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Legal</a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/privacypolicy")}
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/termsConditions")}
                    >
                      Terms & conditions
                    </a>
                  </li>
                  <li className="footer-list-con">
                    <a
                      role="button"
                      onClick={() => handleNavigate("/refundpolicy")}
                    >
                      Refund policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-9 p-0">
                <ul className="footer-list">
                  <li className="footer-list-head">
                    <a>Support</a>
                  </li>
                  <li className="footer-list-con">
                    <a role="button">info@insta-guard.co.uk</a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/developer?id=FCEM+LIMITED" target="blank">
                      <img style={{width: "200px"}} src={playstore} alt="playstore"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://apps.apple.com/in/developer/insta-guard/id1735265056" target="blank">
                      <img style={{width: "200px"}} src={appstore} alt="appstore"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copy-right">
        Copyright © 2024. Fcem Ltd trading as Insta-guard
      </p>
    </div>
  );
}

export default Footer;
