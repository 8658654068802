import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { STRIPE_CLIENT_SECRET } from "../config/Const";

const Gpay = ({ amount, getToken, currency }) => {
  // const stripe = useStripe();
  // const elements = useElements();
  const stripe = useStripe();
  const elements = useElements();

  let value = amount * 100;
  // console.log(currency);
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        // Fetch the client secret from your server
        if (!stripe || !elements) {
          return;
        }

        var paymentRequest = stripe.paymentRequest({
          country: "US",
          currency: currency,
          total: {
            label: "Insta Guard",
            amount: value,
          },
          requestPayerName: true,
          requestPayerEmail: true,


        });

        var prButton = elements.create("paymentRequestButton", {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              height: "40px",
              theme: "light-outline",
              borderRadius: "50px",
              // width: 100,
              // padding: "10px 20px",
            },
            complete: {
              backgroundColor: 'green'
            }
          },

        });

        // paymentRequest.show()
        paymentRequest
          .canMakePayment()
          .then(function (result) {
            // alert(JSON.stringify(result))
            // console.log(result, "res");
            if ((result && result.applePay) || (result && result.googlePay)) {
              // if(result.applePay){
              //   payment_type ="applePay";
              // }else if(result.googlePay){
              //   payment_type ="googlePay";
              // }
              document.getElementById("wallet_payment_error").style.display =
                "none";
              prButton.mount("#payment-request-button");
              document.getElementById("pay-class").style.display = "block";
            } else {
              document.getElementById("pay-class").style.display = "none";
              document.getElementById("payment-request-button").style.display =
                "none";
              document.getElementById("wallet_payment_error").style.display =
                "block";
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert(error)
          });
      } catch (error) {
        alert(error)

        console.error("Error:", error);
      }

      try {
        paymentRequest.on("paymentmethod", async function (res) {
          let payment_type = await res.walletName;
          let token;
          // console.log(res.paymentMethod.id, 'ontoken');

          // const dataObj = {
          //   amount:value,
          //   currency:'gbp',
          //   capture_method: 'manual' ,
          //   payment_method:res.paymentMethod.id
          // }
          const response = await fetch(`https://api.stripe.com/v1/payment_intents?amount=${value}&currency=${currency}&payment_method=${res.paymentMethod.id}&capture_method=manual`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + STRIPE_CLIENT_SECRET,
              'Content-Type': 'application/x-www-form-urlencoded',
            },

          }).then((response) => response.json())
            .then((data) => {
              token = data.client_secret;
              // alert(JSON.stringify(data))
              if (data.status == 'requires_confirmation') {
                stripe.confirmCardPayment(
                  token,
                  { payment_method: res.paymentMethod.id },
                  // { handleActions: false }
                ).then(function (confirmResult) {
                  // alert(JSON.stringify(confirmResult.paymentIntent.id));

                  console.log(confirmResult, 'ccccccccc')
                  if (confirmResult.error) {
                    res.complete('fail');
                  } else {
                    res.complete('success');
                    if (confirmResult.paymentIntent.status === "requires_action") {
                      stripe.confirmCardPayment(token).then(function (result) {
                        if (result.error) {
                          alert(result.error)
                        } else {
                          toast.success('Payment Authorized Successfully')

                          // setTimeout(() => {
                          getToken(result.paymentIntent.id);
                          // }, 1000);
                          console.log(confirmResult, 'hhhhhhhhh')

                        }
                      });
                    } else {
                      // setTimeout(() => {
                      getToken(confirmResult.paymentIntent.id);
                      toast.success('Payment Authorized Successfully')
                      // }, 1000);
                      // console.log(confirmResult)
                    }
                  }
                });

              } else {
                setTimeout(() => {
                  getToken(data.id);
                }, 1000);
              }

            });

          // Confirm the PaymentIntent without handling potential next actions (yet).

        });
      } catch (error) {
        alert(error)
      }

    };

    fetchClientSecret();
  }, [stripe, elements]);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "black",
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#e5424d",
        },
      },
    },
    hidePostalCode: true,
  };

  const [btnLoad, setBtnLoad] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <div>
      <form>
        {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}

        {/* <span className='card-err'>
                    {errorMessage}
                </span> */}
        <div id="payment-request-button" className="w-100"></div>
        <div
          className="pay-class mt-2 rounded-2 bg-danger "
          style={{ visibility: "hidden" }}
          id="pay-class"
        >
          sscwefffffffffffffyugggggggggggggg
        </div>

        <div
          className="pay-con"
          id="wallet_payment_error"
          style={{ display: "none" }}
        >
          {/* Payment type does not supported on this device. */}
        </div>
        {/* <button type="button" className={btnLoad ? "book-spinner dot-spinner spinner-border spinner-border-sm mt-3" : "card-btn"} onClick={(e) => handleGooglePayClick(e)} disabled={!stripe || !elements || btnLoad} >{btnLoad ? "" : "Pay with Google Pay"}</button> */}
      </form>
      <ToastContainer />
    </div>
  );
};
export default React.memo(Gpay);
