import homeBg from '../asserts/home-bg.png'
import catImg01 from '../asserts/catImg01.png'
import contentImg from '../asserts/contentImg.png'
import contentBg from '../asserts/contentBg.png'
import guardBg from '../asserts/guardBg.png'
import skill01 from '../asserts/skill.jpg'
import skill02 from '../asserts/skill02.jpeg'
import subCat01 from '../asserts/subCat01.png'
import subCat02 from '../asserts/subCat02.png'
import subCat03 from '../asserts/subCat03.png'
import aboutImg01 from '../asserts/aboutImg01.png'
import aboutImg02 from '../asserts/aboutImg02.png'
import aboutImg03 from '../asserts/aboutImg03.png'
import aboutImg04 from '../asserts/aboutImg04.png'
import aboutImg05 from '../asserts/aboutImg05.jpg'
import aboutImg06 from '../asserts/aboutImg06.png'
import aboutImg07 from '../asserts/aboutImg07.png'
import contant_01 from '../asserts/contant_01.jpg'
import seclist01 from '../asserts/seclist01.jpg'
import seclist02 from '../asserts/seclist02.jpg'
import secitem03 from '../asserts/secitem03.jpg'
import secitem04 from '../asserts/secitem04.jpg'
import secitem05 from '../asserts/secitem05.jpg'
import police01 from '../asserts/police.jpg'
import police02 from '../asserts/police02.jpg'
import police03 from '../asserts/police03.jpg'
import bannerNew from '../asserts/bannerNew.jpeg'
import alert from '../asserts/alert.jpg'
import stripe from '../asserts/stripe.png'
import paypal from '../asserts/paypal.png'
import sideimage from '../asserts/sideimage.jpeg'
import QaImg01 from '../asserts/QaImg01.jpg'
import sos from '../asserts/sos.jpg'
import map from '../asserts/map.jpg'
import hireImg01 from '../asserts/hireImg01.jpg'
import police003 from '../asserts/police03.jpeg'
import works from '../asserts/works.jpg'
import contant_02 from '../asserts/contant_02.jpg'
import sosBtn from '../asserts/sosBtn.jpg'
import cusReview from '../asserts/cusReview.png'
import sos001 from '../asserts/sos01.jpg'
import sos002 from '../asserts/sos01.png'

export const images = {
    homeBg,
    catImg01,
    contentImg,
    contentBg,
    guardBg,
    skill01,
    skill02,
    subCat01,
    subCat02,
    subCat03,
    aboutImg01,
    aboutImg02,
    aboutImg03,
    aboutImg04,
    contant_01,
    seclist01,
    seclist02,
    secitem03,
    secitem04,
    police01,
    police02,
    police03,
    bannerNew,
    alert,
    stripe,
    paypal,
    sideimage,
    secitem05,
    QaImg01,
    sos,
    map,
    hireImg01,
    aboutImg05,
    aboutImg06,
    aboutImg07,
    police003,
    works,
    contant_02,
    sosBtn,
    cusReview,
    sos001,
    sos002
}