import { IoMail } from "@react-icons/all-files/io5/IoMail";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import FetchApi from '../config/ApiCal';
import ContactMap from './ContactMap';

function ContactUs() {
    const [state, setState] = useState({
        name: '',
        email: '',
        notes: '',
    })
    const [btnLoad, setBtnLoad] = useState(false)

    const [err, setErr] = useState({
        nameErr: false,
        emailErr: false,
        notesErr: false,
    })
    const formValidation = () => {
        let isValid = true;
        let nameErr = false;
        let emailErr = false;
        let notesErr = false;

        if (state.name == "") {

            nameErr = true;
            isValid = false;
        }
        else {
            nameErr = false;
        }

        if (state.email == "") {
            emailErr = true
            isValid = false;
        }
        else {
            emailErr = false;
        }

        if (state.email != "") {
            let lastAtPos = state.email.lastIndexOf("@");
            let lastDotPos = state.email.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    state.email.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isValid = false;
                emailErr = true;
            }
        }

        if (state.notes == "") {
            notesErr = true
            isValid = false;
        }
        else {
            notesErr = false;
        }

        if (!isValid) {

            setErr({
                nameErr: nameErr,
                emailErr: emailErr,
                notesErr: notesErr,
            });
        }
        else {
            setErr({
                nameErr: false,
                emailErr: false,
                notesErr: false,
            });
        }
        return isValid;
    }

    const handleSubmit = (e) => {
        setBtnLoad(true)
        e.preventDefault();
        if (formValidation()) {
            let params = {
                name: state.name,
                email: state.email,
                notes: state.notes
            };
            FetchApi('contactUs', params).then((data) => {
                if (data.message == "Request Sent Successfully") {
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setState({
                        name: "",
                        email: "",
                        notes: ""
                    })
                    setBtnLoad(false)
                }
                else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setBtnLoad(false)
                }
            })
        }
        else {
            setBtnLoad(false)
        }
    }




    return (
        <div className='container'>
            {/* <div className='work-head'>Home <IoIosArrowForward className='arr-ico' color='#2a2f83' size={22} />&nbsp;<span>Privacy Policy</span></div> */}
            <div>
                <p className='work-sub-head'>Why  <span>Contact Us?</span></p>
                <p className='work-desc text-muted'>Contacting Insta-guard for security on demand offers several benefits and advantages:</p>
                <div className='my-3'>
                    <ul className='list-style-none ul-lister'>
                        <li>
                            <strong>Immediate Assistance:</strong>
                            <br />
                            In emergency situations or when security needs arise
                        </li>
                        <li>
                            <strong>Flexible Solutions:</strong>
                            <br />
                            Insta-guard offers flexibility in the types of security services it provides.
                        </li>
                        <li>
                            <strong>24/7 Availability:</strong> <br />  Insta-guard operates round-the-clock
                        </li>
                        <li>
                            <strong>Professional Expertise:</strong> <br /> Insta-guard employs experienced security professionals, including ex-police officers and military personnel with close protection licenses.
                        </li>
                        <li>
                            <strong>Peace of Mind:</strong> <br /> Knowing that you have access to security on demand can provide peace of mind, especially in uncertain or high-risk situations.
                        </li>
                        <li>
                            <strong>Efficient Communication:</strong> <br />      Insta-guard's communication channels are designed to facilitate quick and efficient contact.

                        </li>
                        <li>
                            <strong>Overall:</strong> <br /> contacting Insta-guard for security on demand offers convenience, flexibility, and peace of mind, ensuring that you have access to professional security services whenever and wherever you need them.
                        </li>

                    </ul>
                </div>
                <div className='contact-us mt-4'>
                    <form autoComplete='off' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className={err.nameErr ? "form-group mb-4 contact-name mx-3 border-err" : "form-group mb-4 contact-name mx-3"}>
                                    <input className="form-control" type="text" placeholder="Full name" onFocus={() => setErr({
                                        ...err,
                                        nameErr: false,
                                    })} onChange={(e) => setState({
                                        ...state,
                                        name: e.target.value,
                                    })} value={state.name} />
                                </div>
                                <div className={err.emailErr ? "form-group mb-4 contact-name mx-3 border-err" : "form-group mb-4 contact-name mx-3"}>
                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email" onFocus={() => setErr({
                                        ...err,
                                        emailErr: false,
                                    })} onChange={(e) => setState({
                                        ...state,
                                        email: e.target.value,
                                    })} value={state.email} />
                                </div>
                                <div className={err.notesErr ? "form-group mb-4 contact-name mx-3 border-err" : "form-group mb-4 contact-name mx-3"}>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Message' onFocus={() => setErr({
                                        ...err,
                                        notesErr: false,
                                    })} onChange={(e) => setState({
                                        ...state,
                                        notes: e.target.value,
                                    })} value={state.notes}></textarea>
                                </div>
                                <button className='con-send-btn mt-4' disabled={btnLoad}>{btnLoad ? <small className="spinner-border spinner-border-sm"></small> : "Send"}</button>
                            </div>
                            <div className='col-md-6'>
                                <div className='contact-conent'>
                                    <p className='contact-head'>Reach us at</p>

                                    <a href='mailto:Support@insta-guard.co.uk' target='_blank' className='text-white text-decoration-none'>
                                        <IoMail color='#832b67' />
                                        &nbsp;&nbsp;&nbsp; Support@insta-guard.co.uk</a>
                                    <br />
                                    <a href='mailto:Recruitment@insta-guard.co.uk' target='_blank' className='text-white text-decoration-none'>
                                        <IoMail color='#832b67' />
                                        &nbsp;&nbsp;&nbsp;Recruitment@insta-guard.co.uk </a>
                                </div>

                            </div>

                        </div>
                    </form>

                </div>
            </div>
            <ToastContainer />
            <ContactMap />
        </div>
    )
}

export default ContactUs